import React, { useCallback, useEffect, useRef, useState } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useUsersColumns } from "../../hooks/useUsersColumns";
import { ApplicationState } from "~/store";
import { enableUser, fetchUsers, removeUser } from "~/store/users/actions";
import { connect } from "react-redux";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { ClientPanelGroup, User } from "~/store/users/types";
import { Dropdown, Table } from "semantic-ui-react";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import { ACTION_TYPE_ENUM } from "../UserTableWrapper/constants";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import MenageUserRole from "../MenageUserRole";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { TTableFilterSession } from "~/utils/tableFilterSession";

type TProps = {
  refreshTrigger: number;
  enableUser: typeof enableUser;
  removeUser: typeof removeUser;
  fetchUsers: typeof fetchUsers;
  loading: boolean;
  users: User[];
  meta: PaginationMeta;
  totalRecords: TotalRecords;
};

const ClientPanelUsers: React.FC<TProps> = ({
  refreshTrigger,
  enableUser,
  removeUser,
  fetchUsers,
  loading,
  users,
  meta,
  totalRecords,
}) => {
  const [tableKey] = useState<string>("client-panel-users");
  const { columnsConfigClientPanel } = useUsersColumns({ tableKey });
  const [columns, setColumns] = useState<TColumn[]>(columnsConfigClientPanel);
  const [sortColumn] = useState<any>("createdTimestamp");
  const [sortDirection] = useState<any>("DESC");
  const [params, setParams] = useState<Object>({});
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<string>("");
  const [actionId, setActionId] = useState<string>("");
  const [actionUserName, setActionUserName] = useState<string>("");
  const [request, setRequest] = useState<boolean>(false);

  const getConfirmText = (): string => {
    switch (confirmType) {
      case ACTION_TYPE_ENUM.ACTIVATE_USER:
        return `Czy na pewno chcesz aktywować użytkownika ${actionUserName}?`;
      case ACTION_TYPE_ENUM.DEACTIVATE_USER:
        return `Czy na pewno chcesz dezaktywować użytkownika ${actionUserName}?`;
      case ACTION_TYPE_ENUM.REMOVE_USER:
        return `Czy na pewno chcesz usunąć użytkownika ${actionUserName}?`;
      default:
        return "";
    }
  };

  const { isClientPanelUser, user_Id } = useClientPanelRoles();

  const getClientPanelUsers = useCallback(
    (params?: Object) => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchUsers(Object.assign(paramsObj, params), isClientPanelUser);
    },
    [
      fetchUsers,
      isClientPanelUser,
      meta.page,
      meta.size,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    if (refreshTrigger) {
      getClientPanelUsers({ ...params, size: meta.size, page: 1 });
    }
  }, [refreshTrigger, getClientPanelUsers, meta.size, params]);

  const getUserName = useCallback((user: User): string => {
    return `${user.username} ${user.email ? `(${user.email})` : ""}`;
  }, []);

  const onActivateUser = (id: string, name: string): void => {
    setConfirmType(ACTION_TYPE_ENUM.ACTIVATE_USER);
    setActionId(id);
    setActionUserName(name);
    setOpenModal(true);
  };

  const onDeactivateUser = (id: string, name: string): void => {
    setConfirmType(ACTION_TYPE_ENUM.DEACTIVATE_USER);
    setActionId(id);
    setActionUserName(name);
    setOpenModal(true);
  };

  const onRemoveUser = (id: string, name: string): void => {
    setOpenModal(true);
    setConfirmType(ACTION_TYPE_ENUM.REMOVE_USER);
    setActionId(id);
    setActionUserName(name);
  };

  const handleCancelClick = (): void => {
    setOpenModal(false);
  };

  const handleConfirmClick = (): void => {
    switch (confirmType) {
      case ACTION_TYPE_ENUM.ACTIVATE_USER:
        enableUser(actionId, true, true);
        break;
      case ACTION_TYPE_ENUM.DEACTIVATE_USER:
        enableUser(actionId, false, true);
        break;
      case ACTION_TYPE_ENUM.REMOVE_USER:
        removeUser(actionId, true);
        break;
      default:
        break;
    }
    setRequest(true);
    setOpenModal(false);
  };

  useEffect(() => {
    if (request && !loading) {
      setRequest(false);
      getClientPanelUsers({ page: 1, size: 15 });
    }
    // eslint-disable-next-line
  }, [loading, getClientPanelUsers]);

  return (
    <DataGridTable
      columns={columns}
      isAdvancedSearchOpen={false}
      loading={loading}
      totalRecords={totalRecords}
      fetchMethod={getClientPanelUsers}
      initSortColumn={sortColumn}
      initSortDirection={sortDirection}
      tableKey={tableKey}
      dictionaries={undefined}
      setColumns={setColumns}
      meta={meta}
    >
      {users.map((user: User, index: number) => (
        <Table.Row key={index}>
          {columns &&
            columns.length &&
            columns.map(
              (column) =>
                column.getCell &&
                column.projection &&
                column.getCell(user, column)
            )}
          <Table.Cell className="col-dropdown-menu-sticky">
            {user.group === ClientPanelGroup.LOCAL_USER ||
            user.group === ClientPanelGroup.LOCAL_ADMIN ||
            user.id === user_Id ? (
              <TableMoreDropdown>
                {user.enabled ? (
                  <Dropdown.Item
                    text="Dezaktywuj użytkownika"
                    style={{ color: "red" }}
                    onClick={() => onDeactivateUser(user.id, getUserName(user))}
                  />
                ) : (
                  <Dropdown.Item
                    text="Aktywuj użytkownika"
                    style={{ color: "green" }}
                    onClick={() => onActivateUser(user.id, getUserName(user))}
                  />
                )}
                <MenageUserRole userId={user.id} userName={getUserName(user)}>
                  <Dropdown.Item text="Zarządzaj prawami użytkownika" />
                </MenageUserRole>
                <Dropdown.Divider />
                <Dropdown.Item
                  text="Usuń użytkownika"
                  style={{ color: "red" }}
                  onClick={() => onRemoveUser(user.id, getUserName(user))}
                />
              </TableMoreDropdown>
            ) : null}
          </Table.Cell>
        </Table.Row>
      ))}

      <ModalConfirm
        modalOpen={openModal}
        contentText={getConfirmText()}
        headerIcon="question circle"
        headerText="app.confirmOperation"
        onCancelClick={handleCancelClick}
        onConfirmClick={handleConfirmClick}
      />
    </DataGridTable>
  );
};

const mapStateToProps = ({ users }: ApplicationState) => ({
  users: users.list,
  loading: users.loadingUsers,
  meta: users.meta,
  totalRecords: users.totalRecords,
});

const mapDispatchToProps = {
  enableUser,
  removeUser,
  fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPanelUsers);
