export enum FactorActionTypes {
  FETCH_FACTORS = "@@payment_methods/FETCH_FACTORS",
  FETCH_FACTORS_SUCCESS = "@@payment_methods/FETCH_FACTORS_SUCCESS",
  FETCH_FACTOR_BY_ID = "@@payment_methods/FETCH_FACTOR_BY_ID",
  FETCH_FACTOR_BY_ID_SUCCESS = "@@payment_methods/FETCH_FACTOR_BY_ID_SUCCESS",
  CHANGE_FACTOR_PRIORITY = "@@payment_methods/CHANGE_FACTOR_PRIORITY",
  CHANGE_FACTOR_PRIORITY_SUCCESS = "@@payment_methods/CHANGE_FACTOR_PRIORITY_SUCCESS",
  CHANGE_FACTOR_PAYMENT_DUE = "@@payment_methods/CHANGE_FACTOR_PAYMENT_DUE",
  CHANGE_FACTOR_PAYMENT_DUE_SUCCESS = "@@payment_methods/CHANGE_FACTOR_PAYMENT_DUE_SUCCESS",
  CHANGE_FACTOR_PAYMENT_DUE_FAILED = "@@payment_methods/CHANGE_FACTOR_PAYMENT_DUE_FAILED",
  CREATE_FACTOR = "@@payment_methods/CREATE_FACTOR",
  CREATE_FACTOR_SUCCESS = "@@payment_methods/CREATE_FACTOR_SUCCESS",
  REMOVE_FACTOR = "@@payment_methods/REMOVE_FACTOR",
  REMOVE_FACTOR_SUCCESS = "@@payment_methods/REMOVE_FACTOR_SUCCESS",
  ADD_DISTRIGO_LIMIT = "@@payment_methods/ADD_DISTRIGO_LIMIT",
  ADD_DISTRIGO_LIMIT_SUCCESS = "@@payment_methods/ADD_DISTRIGO_LIMIT_SUCCESS",
  IMPORT_FACTOR_LIMITS_FROM_BANK = "@@payment_methods/IMPORT_FACTOR_LIMITS_FROM_BANK",
  IMPORT_FACTOR_LIMITS_FROM_BANK_SUCCESS = "@@payment_methods/IMPORT_FACTOR_LIMITS_FROM_BANK_SUCCESS",
  DOWNLOAD_FACTOR_ORDERS_CSV = "@@payment_methods/DOWNLOAD_FACTOR_ORDERS_CSV",
  DOWNLOAD_FACTOR_ORDERS_CSV_END = "@@payment_methods/DOWNLOAD_FACTOR_ORDERS_CSV_END",
}

export type CustomerPaymentFactor = {
  paymentFactorId: string;
  paymentDueId: string;
  priority: number;
  factorLimitAgreementAmount: number;
  factorLimitAmount: number;
  factorUnpaidInvoicesAmount: number;
  factorNotBoughtInvoicesAmount: number;
  ordersAmount: number;
  invoicesAmount: number;
  distrigoLimits?: CustomerPaymentFactorDistrigoLimit[];
  currentDistrigoLimit?: CustomerPaymentFactorDistrigoLimit;
  currentLimitAssigned: number;
  currentLimitTotal: number;
};

export type CustomerPaymentFactorDTO = {
  paymentFactorId: string;
  paymentDueId: string;
  priority: number | undefined;
};

export type CustomerPaymentFactorDistrigoLimit = {
  amount: number;
  validUntil: string;
  authorUsername?: string;
  creationTime: string;
  creationComment: string;
};

export type CustomerPaymentFactorDistrigoLimitDTO = {
  amount: number;
  validUntil: string;
  creationComment: string;
};

export type CustomerPaymentFactorState = {
  readonly paymentDueChanged: boolean;
  readonly loading: boolean;
  readonly creating: boolean;
  readonly removing: boolean;
  readonly created: boolean;
  readonly creatingLimit: boolean;
  readonly priorityChanged: boolean;
  readonly limitCreated: boolean;
  readonly createdFactorId: string | null;
  readonly customerPaymentFactors: CustomerPaymentFactor[];
  readonly customerPaymentFactor: CustomerPaymentFactor | null;
  readonly downloadXLSPending: boolean;
};
