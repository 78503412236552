import { Button, Header, Modal, Table } from "semantic-ui-react";
import React, { useState } from "react";
import CommonLoader from "~/components/Loaders/CommonLoader";
import { callApi } from "~/utils/api";
import { parsePrice } from "~/utils/parsePrice";
import { parseDate } from "~/utils/dateUtils";
import { Link } from "react-router-dom";
import TablePaginationFooter from "~/components/TablePaginationFooter/TablePaginationFooter";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import { DictionaryItem } from "~/store/dictionaries/types";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import { useDispatch } from "react-redux";
import { handleError } from "~/store/error/actions";
import { downloadFactorOrdersCsv } from "~/store/factors/actions";
import translations from "~/utils/translations";

type TProps = {
  deliveryCustomerRRID: string;
  factorId: string;
  downloadCSVPending: boolean;
  forClientPanel?: boolean;
};

interface FactorOrders {
  orderID: string;
  orderNumber: string;
  createDate: string;
  amount: number;
  status: string;
  serviceBoxOrderNo: string | null;
}

const OrderStatusDictionary: DictionaryItem[] = [
  {
    key: "PENDING",
    value: "PENDING",
    text: "Oczekiwanie",
  },
  {
    key: "COMPLETED",
    value: "COMPLETED",
    text: "Zakończone",
  },
];

const OrdersModal: React.FC<TProps> = ({
  deliveryCustomerRRID,
  factorId,
  downloadCSVPending,
  forClientPanel,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [orders, setOrders] = useState<FactorOrders[] | undefined>();
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<TotalRecords>();
  const [meta, setMeta] = useState<PaginationMeta>();

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
    getOrders();
  };

  const getOrders = (params?: any): void => {
    setRequestPending(true);
    callApi(
      "GET",
      `${forClientPanel ? "/customer-panel" : ""}/customers/${deliveryCustomerRRID}/factor/${factorId}?page=${params && params.page ? params.page : 0}&size=${params && params.size ? params.size : 15}`
    )
      .then((res) => {
        setOrders(res.content);
        setTotalRecords({
          total: res.totalElements,
          filtered: res.filteredElements,
        });
        setMeta({
          page: res.number + 1,
          pages: res.totalPages,
          size: res.size,
        });
        setRequestPending(false);
      })
      .catch((e) => {
        setRequestPending(false);
        dispatch(handleError(e, false));
      });
  };

  const downloadCSV = (): void => {
    dispatch(
      downloadFactorOrdersCsv(
        deliveryCustomerRRID,
        factorId,
        `${deliveryCustomerRRID} - Factor${factorId} - zamówienia`,
        !!forClientPanel
      )
    );
  };

  const pageChange = (page: number): void => {
    getOrders({ page: page - 1 });
  };

  const pageSizeChange = (size: number): void => {
    getOrders({ size });
  };

  return (
    <Modal
      size="large"
      trigger={
        <div
          style={{ position: "absolute", right: 0, bottom: 80, padding: 0 }}
          color="orange"
        >
          <SmartPopup
            description={translations.format("app.customer.factoring.popup2")}
            position="top right"
          >
            <Button
              color="orange"
              style={{ height: "absolute", right: 0, bottom: 80 }}
            >
              {translations.format("app.customer.factoring.ordered")}
            </Button>
          </SmartPopup>
        </div>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header>
        <div style={{ display: "flex", width: "100%" }}>
          <i
            aria-hidden="true"
            className="shop icon"
            style={{ fontSize: "1.5em", marginRight: "0.75rem" }}
          ></i>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{translations.format("app.customer.factoring.ordered")}</span>
            {!requestPending ? (
              <Button
                loading={downloadCSVPending}
                onClick={() => downloadCSV()}
                primary
                disabled={!orders || !orders.length}
              >
                {translations.format("app.data-export")}
              </Button>
            ) : null}
          </div>
        </div>
      </Header>
      <Modal.Content>
        <CommonLoader loading={requestPending} />
        {
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {translations.format("app.customer.factoring.LDCnr")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {translations.format("app.customer.factoring.ServiceBoxNr")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {translations.format("app.customer.factoring.created")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {translations.format("app.customer.factoring.grossValue")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {translations.format("app.status")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {orders && orders.length ? (
                orders.map((order) => (
                  <Table.Row key={order.createDate + Math.random()}>
                    <Table.Cell>
                      <Link target={"_blank"} to={`/order/${order.orderID}`}>
                        {order.orderNumber}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{order.serviceBoxOrderNo || "-"}</Table.Cell>
                    <Table.Cell>{parseDate(order.createDate)}</Table.Cell>
                    <Table.Cell>{parsePrice(order.amount)}</Table.Cell>
                    <Table.Cell>
                      <DictionaryLabel
                        value={order.status}
                        dictionary={OrderStatusDictionary}
                        hideKey
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <TableNoResults />
              )}
            </Table.Body>
            {meta && (
              <TablePaginationFooter
                meta={meta}
                totalRecords={totalRecords}
                onPageChange={pageChange}
                onPageSizeChange={pageSizeChange}
              />
            )}
          </Table>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>
          {translations.format("app.close")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OrdersModal;
