import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  AvailableRolesInGroups,
  PasswordPolicy,
  User,
  UsersActionTypes,
} from "./types";
import {
  fetchUsersSuccess,
  addSuccess,
  enableUser,
  removeUser,
  fetchAvailableRoles,
  fetchUserRoles,
  actionSuccess,
  fetchAvailableRolesSuccess,
  fetchUserRolesSuccess,
  fetchPasswordPoliciesSuccess,
  exportUserListToXls,
  changeUserRolesAndLimitSuccess,
  fetchUserRolesFailed,
  fetchAvailableRolesFailed,
  fetchUserById,
  fetchUserByIdSuccess,
  fetchUserByIdFailed,
  changeUserRolesAndLimit,
  exportFP0536ToCsv,
  exportFP0536ToCsvSuccesss,
  exportFP0536ToCsvFailed,
  fetchClientPanelDeliveryPoints,
  fetchClientPanelDeliveryPointsSuccess,
  fetchUsersError,
  fetchUsers,
  fetchPasswordPolicies,
} from "./actions";
import { callApi, callApiDownload } from "~/utils/api";
import { toastError, toastSuccess } from "~/utils/toast";
import { handleError } from "~/store/error/actions";
import { downloadFile } from "~/utils/downloadFile";
import { Paginable } from "../types";
import translations from "~/utils/translations";

function* handleFetchUserById(action: ReturnType<typeof fetchUserById>) {
  try {
    const user: User = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/users/${action.payload.id}`
    );
    yield put(fetchUserByIdSuccess(user));
  } catch (error) {
    yield put(fetchUserByIdFailed());
    toastError("app.error.common.load", "app.error.user.load");
  }
}

function* handleFetchUsers(action: ReturnType<typeof fetchUsers>) {
  try {
    let filters = "";
    let sort = "";
    if ("filters" in action.payload) {
      action.payload.filters.forEach((filter: any) => {
        filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
      });
    }

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn.replace(".", "_")},${
        action.payload.sortDirection
      }`;
    }
    const users: Paginable<User> = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/users?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );
    yield put(fetchUsersSuccess(users));
  } catch (e) {
    const error = e as Error;
    yield put(
      handleError(
        error as Error,
        true,
        "Błąd pobierania listy użytkowników",
        "Twoje konto jest niepoprawnie skonfigurowane. Proszę skontaktuj się z administratorem systemu."
      )
    );
    yield put(fetchUsersError());
  }
}

function* handleAddUser(action: any) {
  try {
    yield call(
      callApi,
      "post",
      `${action.meta ? "/customer-panel" : ""}/users`,
      action.payload
    );
    yield put(addSuccess());
  } catch (e) {
    const error = e as Error;
    yield put(
      handleError(
        error as Error,
        false,
        "Błąd podczas dodawania użytkownika",
        error.message
      )
    );
  }
}

function* handleEnableUser(action: ReturnType<typeof enableUser>) {
  try {
    yield call(
      callApi,
      "put",
      `${action.meta ? "/customer-panel" : ""}/users/${action.payload.userId}/status`,
      {
        enabled: action.payload.enable,
      }
    );
    yield put(actionSuccess());
  } catch (error) {
    yield put(actionSuccess());
    toastError(
      "app.error.common.update",
      action.payload.enable
        ? "app.error.user.enable"
        : "app.error.user.disenable"
    );
  }
}

function* handleRemoveUser(action: ReturnType<typeof removeUser>) {
  try {
    yield call(
      callApi,
      "delete",
      `${action.meta ? "/customer-panel" : ""}/users/${action.payload}`
    );
    yield put(actionSuccess());
  } catch (e) {
    const error = e as Error;
    yield put(actionSuccess());
    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.common.delete"),
        error.message || translations.format("app.error.user.remove")
      )
    );
  }
}

function* handleFetchAvailableRoles(
  action: ReturnType<typeof fetchAvailableRoles>
) {
  try {
    const availableRoles: AvailableRolesInGroups = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/users/${action.payload}/role-mappings/realm/available`
    );
    yield put(fetchAvailableRolesSuccess(availableRoles));
  } catch (error) {
    yield put(fetchAvailableRolesFailed());
    toastError(
      "app.error.common.load",
      "app.error.users.availableUserRoles.load"
    );
  }
}

function* handleFetchUserRoles(action: ReturnType<typeof fetchUserRoles>) {
  try {
    const userRoles: AvailableRolesInGroups = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/users/${action.payload}/role-mappings/realm`
    );
    yield put(fetchUserRolesSuccess(userRoles));
  } catch (error) {
    yield put(fetchUserRolesFailed());
    toastError("app.error.common.load", "app.error.users.userRoles.load");
  }
}

function* handleChangeUserRolesAndLimit(
  action: ReturnType<typeof changeUserRolesAndLimit>
) {
  const userId = action.payload.userId;
  try {
    yield call(
      callApi,
      "put",
      `${action.meta ? "/customer-panel" : ""}/users/${userId}/role-mappings/realm`,
      action.payload.dataToSend
    );
    yield put(changeUserRolesAndLimitSuccess());
    yield put(fetchUserRoles(userId, action.meta));
    yield put(fetchAvailableRoles(userId, action.meta));

    action.payload.cb();

    toastSuccess(
      "app.user.change.roles.and.limit.success",
      "app.info.common.success",
      { timeOut: 7000 }
    );
  } catch (error) {
    yield put(changeUserRolesAndLimitSuccess());
    toastError(
      "app.error.common.load",
      "app.error.users.change.roles.and.limit"
    );
  }
}

function* handleFetchPasswordPolicies(
  action: ReturnType<typeof fetchPasswordPolicies>
) {
  try {
    const passwordPolicies: PasswordPolicy[] = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/users/passwordPolicy`
    );
    yield put(fetchPasswordPoliciesSuccess(passwordPolicies));
  } catch (error) {
    toastError("app.error.password-policies", "app.error.users.load");
  }
}

function* handleExportUserListToXls(
  action: ReturnType<typeof exportUserListToXls>
) {
  try {
    const response: File = yield call(
      callApiDownload,
      "get",
      "/users/generate",
      action.payload
    );

    downloadFile(
      response,
      action.payload,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
    );
  } catch (error) {
    toastError("app.error.export-to-xls-file", "app.error.users.export");
  }
}

function* handleDownloadFP0536ToCsv(
  action: ReturnType<typeof exportFP0536ToCsv>
) {
  try {
    const response: File = yield call(
      callApiDownload,
      "get",
      "/hazardous-goods/generate/csv",
      action.payload
    );
    yield put(exportFP0536ToCsvSuccesss());
    downloadFile(response, action.payload + ".csv");
  } catch (error) {
    yield put(exportFP0536ToCsvFailed());
    toastError("app.error.export-to-csv-file", "app.error.fp0536.export");
  }
}

function* handleFetchDeliveryPoints(
  action: ReturnType<typeof fetchClientPanelDeliveryPoints>
) {
  try {
    const response: Paginable<any> = yield call(
      callApi,
      "get",
      `/customer-panel/customers/delivery-points?query=&size=100`
    );
    yield put(fetchClientPanelDeliveryPointsSuccess(response.content));
  } catch (error) {
    yield put(fetchClientPanelDeliveryPointsSuccess([]));
    toastError("Błąd", "Nie udało się pobrać punktów dostawy");
  }
}

function* watchFetchUserById() {
  yield takeEvery(UsersActionTypes.FETCH_USER_BY_ID, handleFetchUserById);
}

function* watchFetchUsersRequest() {
  yield takeEvery(UsersActionTypes.FETCH_USERS_REQUEST, handleFetchUsers);
}

function* watchAddUser() {
  yield takeEvery(UsersActionTypes.ADD_USER, handleAddUser);
}

function* watchEnableUser() {
  yield takeEvery(UsersActionTypes.ENABLE_USER, handleEnableUser);
}

function* watchRemoveUser() {
  yield takeEvery(UsersActionTypes.REMOVE_USER, handleRemoveUser);
}

function* watchFetchUserRoles() {
  yield takeEvery(UsersActionTypes.FETCH_USER_ROLES, handleFetchUserRoles);
}

function* watchFetchAvailableRoles() {
  yield takeEvery(
    UsersActionTypes.FETCH_AVAILABLE_ROLES,
    handleFetchAvailableRoles
  );
}

function* watchChangeUserToRoleAndLimit() {
  yield takeEvery(
    UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT,
    handleChangeUserRolesAndLimit
  );
}

function* watchFetchPasswordPolicies() {
  yield takeEvery(
    UsersActionTypes.FETCH_PASSWORD_POLICIES,
    handleFetchPasswordPolicies
  );
}

function* watchExportUserListToXls() {
  yield takeEvery(
    UsersActionTypes.EXPORT_USER_LIST_TO_XLS,
    handleExportUserListToXls
  );
}

function* watchexportFP0536ToCsv() {
  yield takeEvery(
    UsersActionTypes.DOWNLOAD_FP0536_CSV,
    handleDownloadFP0536ToCsv
  );
}

function* watchFetchDeliveryPoints() {
  yield takeEvery(
    UsersActionTypes.FETCH_DELIVERY_POINTS,
    handleFetchDeliveryPoints
  );
}

function* usersSaga() {
  yield all([
    fork(watchFetchUserById),
    fork(watchChangeUserToRoleAndLimit),
    fork(watchFetchUsersRequest),
    fork(watchAddUser),
    fork(watchEnableUser),
    fork(watchRemoveUser),
    fork(watchFetchAvailableRoles),
    fork(watchFetchUserRoles),
    fork(watchFetchPasswordPolicies),
    fork(watchExportUserListToXls),
    fork(watchexportFP0536ToCsv),
    fork(watchFetchDeliveryPoints),
  ]);
}

export default usersSaga;
