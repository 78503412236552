import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { callApi, callApiDownload, callApiUploadFile } from "~/utils/api";
import { handleError } from "~/store/error/actions";
import {
  addLimitDistrigo,
  addLimitDistrigoSuccess,
  changeFactorPaymentDue,
  changeFactorPaymentDueFailed,
  changeFactorPaymentDueSuccess,
  changeFactorPriority,
  changeFactorPrioritySuccess,
  createFactor,
  createFactorSuccess,
  downloadFactorOrdersCsv,
  downloadFactorOrdersCsvEnd,
  fetchFactorById,
  fetchFactorByIdSuccess,
  fetchFactors,
  fetchFactorsSuccess,
  importFactorLimits,
  importFactorLimitsSuccess,
  removeFactor,
  removeFactorSuccess,
} from "./actions";
import { CustomerPaymentFactor, FactorActionTypes } from "./types";
import { toastSuccess } from "~/utils/toast";
import { downloadFile } from "~/utils/downloadFile";
import translations from "~/utils/translations";

function* handleFetchFactors(action: ReturnType<typeof fetchFactors>) {
  try {
    const { customerId } = action.payload;
    const response: CustomerPaymentFactor[] = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/customers/${customerId}/factors`
    );
    yield put(fetchFactorsSuccess(response));
  } catch (error) {
    yield put(handleError(error as Error, false));
  }
}

function* handleFetchFactorById(action: ReturnType<typeof fetchFactorById>) {
  try {
    const { customerId, factorId } = action.payload;
    const response: CustomerPaymentFactor = yield call(
      callApi,
      "get",
      `/customers/${customerId}/factors/${factorId}`
    );

    yield put(fetchFactorByIdSuccess(response));
  } catch (error) {
    yield put(handleError(error as Error, false));
  }
}

function* handleImportFactorLimits(
  action: ReturnType<typeof importFactorLimits>
) {
  try {
    const { file } = action.payload;
    yield call(
      callApiUploadFile,
      `/customers/factors/limit/import`,
      file,
      "file"
    );
    yield put(importFactorLimitsSuccess());
    toastSuccess(
      "app.info.factor.limits.import.success",
      "app.info.common.success"
    );
  } catch (e) {
    const error = e as Error;
    yield put(
      handleError(
        error,
        false,
        "Import limitów faktorów zakończył się niepowodzeniem",
        error.message
      )
    );
  }
}

function* handleChangeFactorPriority(
  action: ReturnType<typeof changeFactorPriority>
) {
  try {
    const { priority, customerId, factorId } = action.payload;
    const response: CustomerPaymentFactor[] = yield call(
      callApi,
      "PATCH",
      `/customers/${customerId}/factors/${factorId}?newPriority=${priority}`
    );
    yield toastSuccess(
      "app.info.factor.priority.change.success",
      "app.info.common.success"
    );
    yield put(changeFactorPrioritySuccess(response));
  } catch (e) {
    const error = e as Error;
    yield put(handleError(error, false, "Wystąpił błąd", error.message));
  }
}

function* handleChangeFactorPaymentDue(
  action: ReturnType<typeof changeFactorPaymentDue>
) {
  try {
    const { paymentDueId, customerId, factorId } = action.payload;
    const response: CustomerPaymentFactor[] = yield call(
      callApi,
      "PATCH",
      `/customers/${customerId}/factors/${factorId}/paymentDue?newPaymentDue=${paymentDueId}`
    );
    yield toastSuccess(
      "app.info.factor.paymentDue.change.success",
      "app.info.common.success"
    );
    yield put(changeFactorPaymentDueSuccess(response));
  } catch (e) {
    const error = e as Error;
    yield put(changeFactorPaymentDueFailed());
    yield put(handleError(error, false, "Wystąpił błąd", error.message));
  }
}

function* handleCreateFactor(action: ReturnType<typeof createFactor>) {
  try {
    const { customerPaymentFactor, customerId } = action.payload;

    const response: string = yield call(
      callApi,
      "post",
      `/customers/${customerId}/factors`,
      { ...customerPaymentFactor }
    );
    yield toastSuccess(
      "app.info.factor.create.success",
      "app.info.common.success"
    );
    yield put(createFactorSuccess(response));
  } catch (e) {
    const error = e as Error;
    yield put(
      handleError(
        error,
        false,
        "Nie udało się aktualizować piorytetu",
        error.message
      )
    );
  }
}

function* handleRemoveFactor(action: ReturnType<typeof removeFactor>) {
  try {
    const { customerId, factorId } = action.payload;
    const response: string = yield call(
      callApi,
      "delete",
      `/customers/${customerId}/factors/${factorId}`
    );
    yield toastSuccess(
      "app.info.factor.remove.success",
      "app.info.common.success"
    );
    yield put(removeFactorSuccess(response.toString()));
  } catch (e) {
    const error = e as Error;
    yield put(
      handleError(error, false, "Nie udało się usunąć faktora", error.message)
    );
  }
}

function* handleAddDistrigoLimit(action: ReturnType<typeof addLimitDistrigo>) {
  try {
    const { distrigoLimitDTO, customerId, factorId } = action.payload;
    const response: CustomerPaymentFactor = yield call(
      callApi,
      "post",
      `/customers/${customerId}/factors/${factorId}/limit-distrigo`,
      { ...distrigoLimitDTO }
    );
    yield toastSuccess("app.info.factor.add.limit", "app.info.common.success");
    yield put(addLimitDistrigoSuccess(response));
  } catch (e) {
    const error = e as Error;
    yield put(handleError(error, false, "Wystąpił błąd", error.message));
  }
}

function* handleDownloadFactorOrdersCSV(
  action: ReturnType<typeof downloadFactorOrdersCsv>
) {
  try {
    const { customerId, factorId, fileName } = action.payload;
    const res: Response = yield call(
      callApiDownload,
      "get",
      `${action.meta ? "/customer-panel" : ""}/customers/${customerId}/factor/${factorId}/generate`,
      action.payload.fileName
    );

    if (res) {
      downloadFile(res, fileName, "text/csv");
    }
    yield put(downloadFactorOrdersCsvEnd());
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("app.download-failed")
      )
    );
  }
}

function* watchFetchPaymentMethodRequest() {
  yield takeEvery(FactorActionTypes.FETCH_FACTORS, handleFetchFactors);
}

function* watchFetchFactorMethodByIdRequest() {
  yield takeEvery(FactorActionTypes.FETCH_FACTOR_BY_ID, handleFetchFactorById);
}

function* watchImportFactorLimits() {
  yield takeEvery(
    FactorActionTypes.IMPORT_FACTOR_LIMITS_FROM_BANK,
    handleImportFactorLimits
  );
}

function* watchFactorPriorityChangeRequest() {
  yield takeEvery(
    FactorActionTypes.CHANGE_FACTOR_PRIORITY,
    handleChangeFactorPriority
  );
}

function* watchFactorPaymentDueChangeRequest() {
  yield takeEvery(
    FactorActionTypes.CHANGE_FACTOR_PAYMENT_DUE,
    handleChangeFactorPaymentDue
  );
}

function* watchCreateFactorMethodRequest() {
  yield takeEvery(FactorActionTypes.CREATE_FACTOR, handleCreateFactor);
}

function* watchRemoveFactorMethodRequest() {
  yield takeEvery(FactorActionTypes.REMOVE_FACTOR, handleRemoveFactor);
}

function* watchAddDistrigoLimitRequest() {
  yield takeEvery(FactorActionTypes.ADD_DISTRIGO_LIMIT, handleAddDistrigoLimit);
}

function* watchDownloadFactorOrdersCSV() {
  yield takeEvery(
    FactorActionTypes.DOWNLOAD_FACTOR_ORDERS_CSV,
    handleDownloadFactorOrdersCSV
  );
}

function* factorSaga() {
  yield all([
    fork(watchFactorPaymentDueChangeRequest),
    fork(watchFetchPaymentMethodRequest),
    fork(watchFetchFactorMethodByIdRequest),
    fork(watchImportFactorLimits),
    fork(watchFactorPriorityChangeRequest),
    fork(watchCreateFactorMethodRequest),
    fork(watchRemoveFactorMethodRequest),
    fork(watchAddDistrigoLimitRequest),
    fork(watchDownloadFactorOrdersCSV),
  ]);
}

export default factorSaga;
